* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 64px;
}

body {
  font-family: var(--main-font);
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  display: inline;
}

#root {
  width: 100%;
}


