/* ========================
/  Color Palette:
/  Gray: #eff0f4
/  Light Blue: #74dbef
/  Blue: #0074e4
/  Dark Blue: #264e86
/ ======================== */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

:root {
  /* ===== Fonts ===== */
  --main-font: 'Montserrat', sans-serif;

  /* ===== Colors ===== */
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --gray: 239, 240, 244;
  --light-blue: 116, 219, 239;
  --blue: 0, 116, 228;
  --dark-blue: 38, 78, 134;

  /* ===== Sizes ===== */
  --computer: 1080px;
  --computer-sm: 900px;
  --ipad: 800px;
  --ipad-sm: 750px;
  --phone: 680px;
  --phone-sm: 550px;
  --phone-xs: 350px;
  --phone-xxs: 260px;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.text-blue {
  color: rgb(var(--blue));
}

.text-bold {
  font-weight: 600;
}

.rotate-180 {
  transform: rotateZ(180deg);
}

/* ============== NavBar ============== */

.nav {
  width: 100%;
  background-color: rgb(var(--blue));
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 2;
}

.nav-content {
  width: var(--computer);
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-list {
  display: flex;
  width: 40%;
  justify-content: space-between;
}

.nav-list-item {
  height: 100%;
}

.nav-services-dropdown {
  margin-top: 20px;
  position: fixed;
  transform: translateX(-10%);
  display: none;
  width: 250px;
  background-color: rgb(var(--gray));
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.nav-services-dropdown-item:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.services-dropdown-link {
  display: inline-block;
  font-size: 14px;
  font-weight: 200;
  width: 100%;
  color: rgb(var(--blue));
  background-color: rgb(var(--gray));
  padding: 15px 40px 15px 20px;
}

.services-dropdown-link:hover {
  filter: brightness(95%);
}

.dropdown-active {
  display: flex;
}

.nav-logo-container {
  display: flex;
}

.nav-logo {
  font-family: var(--main-font);
  font-size: 20px;
  font-weight: 300;
  white-space: nowrap;
  color: rgb(var(--white));
}

.nav-down-arrow {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 15px;
  transition: transform .5s;
}

.nav-down-arrow > :first-child {
  font-size: 24px;
  color: white;
}

.mobile-nav-container {
  display: none;
}

.mobile-nav-container * {
  width: 100%;
}

.mobile-nav-list-item {
  margin-bottom: 20px;
}

.mobile-nav-link {
  font-size: 20px;
  color: white;
  padding: 10px 0;
  cursor: pointer;
}

.nav-link {
  font-size: 16px;
  font-weight: 200;
  color: rgb(var(--white));
}

.nav-link:hover {
  border-bottom: solid 1.5px rgb(var(--white));
}

/* ============== Banner ============== */
.banner {
  margin-top: 63px;
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.banner-background {
  filter: brightness(40%);
  height: 500px;
  width: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(255, 255, 255, 0)), url(./Media/NoHoWest.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  position: absolute;
}

.banner-content {
  position: absolute;
  z-index: 1;
  width: var(--computer);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4% 0;
}

.banner-content * {
  padding: 0 0 25px 0;
}

/*
.banner-header {
  color: rgb(var(--white));
  font-size: 20px;
} */

.banner-text-lg {
  color: rgb(var(--white));
  font-size: 30px;
  width: 65%;
}

.banner-text-sm {
  color: rgb(var(--white));
  font-size: 16px;
  font-weight: 300;
  width: 65%;
}

.banner-btn {
  width: 220px;
  padding: 13px 0;
  border: solid 2.5px rgb(var(--blue));
  border-radius: 10px;
  color: rgb(var(--white));
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  transition: background-color .2s;
}

.banner-btn:hover {
  background-color: rgb(var(--blue));
  cursor: pointer;
}

/* ============== Services ============== */
.services {
  width: var(--computer);
  padding: 100px 0;
  display: flex;
  flex-direction: column;
}

.services > :not(li) {
  margin-bottom: 30px;
}

.services li {
  text-indent: 20px;
  margin-bottom: 5px;
}

.services > ul > ul > li {
  text-indent: 40px;
  font-style: italic;
}

.services-header {
  font-size: 24px;
}

.services-subheader {
  padding-top: 30px;
}

.services-text {
  font-size: 16px;
}

.services-helpful-links {
  display: flex;
  flex-direction: column;
}

.services-helpful-links > * {
  margin-bottom: 30px;
}

.helpful-links-container {
  display: flex;
  justify-content: space-between;
}

.helpful-links-col {
  display: flex;
  flex-direction: column;
}

.services-helpful-link {
  color: rgb(var(--blue));
  margin-bottom: 10px;
}

.services-helpful-link:active {
  color: rgb(var(--dark-blue));
}

/* ============== Projects ============== */ 
.projects {
  width: 100%;
  background-color: rgb(var(--dark-blue));
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-header {
  font-size: 24px;
  margin: 50px 0;
  color: white;
  width: var(--computer);
}

.project-images-container {
  display: flex;
  width: 100%;
}

.project-image {
  height: 200px;
  width: 20vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.image-1 {
  background-image: url(./Media/9911-pico.jpeg);
}

.image-2 {
  background-image: url(./Media/alexan-noho.jpeg);
}

.image-3 {
  background-image: url(./Media/lumen.jpeg);
}

.image-4 {
  background-image: url(./Media/michelson.jpeg);
}

.image-5 {
  background-image: url(./Media/symmetry.jpeg);
}

.project-container {
  display: flex;
  width: var(--computer);
  justify-content: space-between;
}

.project-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.project-title {
  color: white;
}

.project-text {
  color: #ddd;
}

.project-title:not(:first-child) {
  margin-top: 30px;
}

/* ============== About Us ============== */ 
.about-us {
  width: 100%;
  background-color: rgb(var(--gray));
  display: flex;
  justify-content: center;
}

.about-us-content {
  width: var(--computer);
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-us-header {
  font-size: 24px;
  margin-bottom: 50px;
}

.about-us-container {
  display: flex;
  justify-content: space-between;
}

.about-us-text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.about-us-text {
  font-size: 16px;
  margin-bottom: 30px;
}

.about-us-resume-link {
  color: rgb(var(--blue));
  align-self: flex-start;
}

.about-us-resume-link:active {
  color: rgb(var(--dark-blue));
}

.about-bama-logo {
  width: 425px;
}

/* ============== Contact Us ============== */
.contact {
  padding: 80px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-text-container {
  width: var(--ipad);
}

.contact * {
  margin-bottom: 30px;
}

.contact-us-text {
  margin: 2.5% 0;
}

.contact-text-lg {
  font-size: 14px;
  font-weight: 400;
}

.contact-text-lg * {
  font-size: 14px;
  font-weight: 600;
  display: inline;
}

.contact-form {
  width: var(--ipad);
}

.contact-form > * {
  margin: 0 0 10px 0;
  font-family: var(--main-font);
}

.contact-form > input {
  width: 100%;
  overflow: hidden;
  min-height: 40px;
  height: 30px;
  outline: none;
  border-radius: 7px;
  padding: 10px 15px; 
  color: rgb(var(--dark-blue));
  border: solid 1px rgba(var(--blue), .2);
  display: block;
  font-size: 14px;
}

.contact-form > select {
  width: 100%;
  overflow: hidden;
  min-height: 40px;
  height: 30px;
  outline: none;
  border-radius: 7px;
  padding: 10px 15px; 
  color: rgb(var(--dark-blue));
  border: solid 1px rgba(var(--blue), .2);
  display: block;
  font-size: 14px;
}

.contact-form-message {
  width: 100%;
  resize: none;
  outline: none;
  overflow: auto;
  min-height: 250px;
  border-radius: 7px;
  padding: 15px; 
  color: rgb(var(--dark-blue));
  border: solid 1px rgba(var(--blue), .2);
  font-size: 14px;
}

.contact-form-submit {
  padding: 6px 12px;
  outline: none;
  border: solid 1.5px rgb(var(--blue));
  border-radius: 10px;
  float: right;
  background-color: rgba(var(--main-gray), .9);
  color: rgb(var(--blue));
  font-size: 14px;
  font-weight: 600;
  transition: background-color .2s, color .2s;
}

.contact-form-submit:hover {
  background-color: rgb(var(--blue));
  color: rgb(var(--white));
  cursor: pointer;
}

::-webkit-input-placeholder {
  color: rgb(var(--dark-blue));
}
:-moz-placeholder { /* Upto Firefox 18, Deprecated in Firefox 19  */
  color: rgb(var(--dark-blue));  
}
::-moz-placeholder {  /* Firefox 19+ */
  color: rgb(var(--dark-blue));  
}
:-ms-input-placeholder {  
  color: rgb(var(--dark-blue));  
}

/* ============== Footer ============== */
.footer {
  width: 100%;
  background-color: rgb(var(--blue));
  display: flex;
  justify-content: center;
  color: rgb(var(--white));
}

.footer-content {
  width: var(--computer);
  padding: 10px 0;
}

.footer-list-item {
  font-size: 16px;
}

/* ============== Media Queries ============== */
@media screen and (max-width: 1100px) {
  .nav-content {
    width: var(--computer-sm);
  }

  .nav-list {
    width: 50%;
  }
  
  .banner-content {
    width: var(--computer-sm);
  }

  .services {
    width: var(--computer-sm);
  }

  .projects-header {
    width: var(--computer-sm);
  }

  .project-container {
    width: var(--computer-sm);
    flex-direction: column;
  }

  .project-column {
    margin-bottom: 0;
  }

  .project-column:last-child {
    margin-bottom: 100px;
  }

  .about-us-content {
    width: var(--computer-sm);
  }

  .about-us-text-container {
    width: 100%;
  }

  .about-bama-logo {
    display: none;
  }
  
  .helpful-links-container {
    flex-direction: column;
  }

  .contact-text-container {
    width: var(--ipad-sm);
  }

  .contact-form {
    width: var(--ipad-sm);
  }

  .footer-content {
    width: var(--computer-sm);
  }
}

@media screen and (max-width: 950px) {
  .nav-content {
    width: var(--ipad);
  }

  .nav-logo {
    font-size: 18px;
  }
  
  .nav-link {
    font-size: 16px;
  }

  .banner {
    margin-top: 55px;
  }
  
  .banner-content {
    width: var(--ipad);
  }

  .services {
    width: var(--ipad);
  }

  .projects-header {
    width: var(--ipad)
  }

  .project-container {
    width: var(--ipad)
  }

  .about-us-content {
    width: var(--ipad);
  }

  .contact-text-container {
    width: var(--ipad);
  }

  .contact-form {
    width: var(--ipad);
  }

  .footer-content {
    width: var(--ipad);
  }

  .footer-list-item {
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .nav-content {
    width: var(--ipad-sm);
  }

  .mobile-nav-container {
    display: none;
  }
  
  .banner-content {
    width: var(--ipad-sm);
  }

  .services {
    width: var(--ipad-sm);
  }

  .projects-header {
    width: var(--ipad-sm)
  }

  .project-container {
    width: var(--ipad-sm)
  }

  .about-us-content {
    width: var(--ipad-sm);
  }

  .contact-text-container {
    width: var(--ipad-sm);
  }

  .contact-form {
    width: var(--ipad-sm);
  }

  .footer-content {
    width: var(--ipad-sm);
  }
}

/* TODO: Change to mobile nav at this breakpoint */
@media screen and (max-width: 810px) {
  .nav {
    flex-direction: column;
    align-items: center;
  }

  .mobile-nav-container {
    display: flex;
    width: var(--phone);
    flex-direction: column;
  }

  .display-none {
    display: none;
  }

  .nav-content {
    width: var(--phone);
  }

  .nav-list {
    display: none;
  }

  .nav-logo-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .nav-down-arrow {
    display: flex;
    align-items: center;
  }
  
  .banner-content {
    width: var(--phone);
  }

  .services {
    width: var(--phone);
  }

  .projects-header {
    width: var(--phone)
  }

  .project-container {
    width: var(--phone)
  }

  .about-us-content {
    width: var(--phone);
  }

  .contact-text-container {
    width: var(--phone);
  }

  .contact-form {
    width: var(--phone);
  }

  .footer-content {
    width: var(--phone);
  }
}

@media screen and (max-width: 700px) {
  .nav-content {
    width: var(--phone-sm);
  }

  .mobile-nav-container {
    width: var(--phone-sm);
  }

  .nav-down-arrow > :first-child {
    font-size: 18px;
  }
  
  .banner-content {
    width: var(--phone-sm);
  }

  .services {
    width: var(--phone-sm);
  }

  .projects-header {
    width: var(--phone-sm)
  }

  .project-container {
    width: var(--phone-sm)
  }

  .about-us-content {
    width: var(--phone-sm);
  }

  .contact-text-container {
    width: var(--phone-sm);
  }

  .contact-form {
    width: var(--phone-sm);
  }

  .footer-content {
    width: var(--phone-sm);
  }
}

@media screen and (max-width: 560px) {
  .nav-content {
    width: var(--phone-xs);
  }

  .nav-logo {
    font-size: 16px;
  }

  .mobile-nav-container {
    width: var(--phone-xs);
  }
  
  .banner-content {
    width: var(--phone-xs);
  }

  /*
  .banner-header {
    display: none;
  } */

  .banner-text-lg {
    font-size: 22px;
  }

  .banner-text-sm {
    font-size: 16px;
  }

  .services {
    width: var(--phone-xs);
  }

  .projects-header {
    width: var(--phone-xs)
  }

  .project-container {
    width: var(--phone-xs)
  }

  .about-us-content {
    width: var(--phone-xs);
  }

  .contact-text-container {
    width: var(--phone-xs);
  }

  .contact-form {
    width: var(--phone-xs);
  }

  .contact-form-submit {
    width: 100%;
  }

  .footer-content {
    width: var(--phone-xs);
  }
}

@media screen and (max-width: 360px) {
  .nav-content {
    width: var(--phone-xxs);
  }

  .nav-down-arrow {
    margin: 0;
  }

  .nav-logo {
    font-size: 13px;
  }

  .mobile-nav-container {
    width: var(--phone-xxs);
  }

  .mobile-nav-link {
    font-size: 16px;
  }
  
  .banner-content {
    width: var(--phone-xxs);
  }

  .banner-text-lg {
    font-size: 22px;
  }

  .banner-text-sm {
    font-size: 16px;
  }

  .services {
    width: var(--phone-xxs);
  }

  .projects-header {
    width: var(--phone-xxs)
  }

  .project-container {
    width: var(--phone-xxs)
  }

  .about-us-content {
    width: var(--phone-xxs);
  }

  .contact-text-container {
    width: var(--phone-xxs);
  }

  .contact-form {
    width: var(--phone-xxs);
  }

  .contact-form-submit {
    width: 100%;
  }

  .footer-content {
    width: var(--phone-xxs);
  }
}
